import { createContext, useState } from "react";

export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
	const [open, setOpen] = useState(false);

	const onOpen = () => setOpen(true);
	const onClose = () => setOpen(false);
	const toggleOpen = () => setOpen(!open);

	return (
		<SidebarContext.Provider
			value={{
				open,
				setOpen,
				onOpen,
				onClose,
				toggleOpen,
			}}
		>
			{children}
		</SidebarContext.Provider>
	);
};
