import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

//? PAGES
import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";
import CookiePage from "./pages/CookiePolicy";
import HomePage from "./pages/Home";
import PortfolioPage from "./pages/Portfolio";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import ProductFlexPosPage from "./pages/ProductFlexPos";
import ProjectSmartConnexxionzPage from "./pages/ProjectSmartConnexxionz";
import VacaturePage from "./pages/Vacature";

//? LARGE COMPONENTS
import Navbar from "./components/Navbar";
import ScrollIndicator from "./components/ScrollIndicator";
import Sidebar from "./components/Sidebar";

import "./App.css";
import { SidebarProvider } from "./components/Sidebar/SidebarProvider";
import ScrollToTop from "./ScrollToTop";

function App() {
	const location = useLocation();

	useEffect(() => {
		fadeInLoader();
	}, [location.key]);

	const fadeInLoader = () => {
		const loader = document.querySelector(".loader");

		loader.classList.add("active");
		setTimeout(() => {
			loader.classList.remove("active");
			return;
		}, 200);
	};

	return (
		<SidebarProvider>
			<Sidebar />
			<ScrollToTop />
			<Navbar />
			<ScrollIndicator />
			<Switch>
				<Route path="/" exact component={HomePage} />
				<Route path="/portfolio" component={PortfolioPage} />
				<Route path="/contact" component={ContactPage} />
				<Route path="/about" component={AboutPage} />
				<Route path="/jobs" component={VacaturePage} />
				<Route path="/cookie-policy" component={CookiePage} />
				<Route path="/privacy-policy" component={PrivacyPolicyPage} />
				<Route path="/project-smartconnexxionz" component={ProjectSmartConnexxionzPage} />
				<Route path="/product-flexpos" component={ProductFlexPosPage} />
			</Switch>
		</SidebarProvider>
	);
}

export default App;
