import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import WOW from 'wowjs';
import 'animate.css';

//? STYLED COMPONENTS
import styled from 'styled-components';

const OuterContainer = styled.div`
	position: relative;
	padding-top: 5rem;
	height: auto;

	&.left {
		margin-right: 1rem;
	}

	&.right {
		margin-left: 1rem;
	}

	@media screen and (min-width: 768px) and (max-width: 1500px) {
		height: auto;
	}

	@media screen and (min-width: 321px) and (max-width: 768px) {
		height: 34rem;
	}

	@media screen and (max-width: 320px) {
		height: 38rem;
	}	
`;

const InnerContainer = styled.div`
	background: ${({ background }) => background.primary};
	background: linear-gradient(
		0deg,
		${({ background }) => background.primary} 30%,
		${({ background }) => background.secondary} 100%
	);
`;

const Tag = styled.div`
	background: ${({ background }) => background};
`;

const SingleProduct = ({ id, title, description, img, tags, pallete, link, position }) => {
	useEffect(() => {
		const wow = new WOW.WOW({
live: false
});
		wow.init();
	}, []);

	return (
		<OuterContainer className={position}>
			<NavLink exact to={link}>
				<img
					loading='lazy'
					src={img}
					alt={`floating ${title}`}
					className='single-project-image absolute top-0 inset-x-0 z-10 px-3 mx-auto wow animate__animated animate__fadeIn'
					data-wow-duration='1.5s'
				/>
				<InnerContainer
					className='h-full w-full relative flex flex-col flex-grow rounded-2xl px-8 pt-20 sm:pt-36 md:pt-44 lg:pt-36 xl:pt-52'
					background={pallete}
				>
					<h1
						className='text-2xl lg:text-3xl text-white font-inter font-bold my-4 md:my-3 wow animate__animated animate__fadeInUp'
						data-wow-delay='0.3s'
					>
						{title}
					</h1>
					<p
						className='paragraph-text text-white font-inter font-light wow animate__animated animate__fadeInUp'
						data-wow-delay='0.5s'
					>
						{description}
					</p>

					<div className='h-auto w-full flex my-10 wow animate__animated animate__fadeInUp' data-wow-delay='0.7s'>
						{tags.map((tag, index) => {
							return (
								<Tag
									key={index}
									background={pallete.background}
									// className='flex justify-center items-center py-2 px-4 bg-red-300 uppercase text-xs lg:text-sm text-white font-inter font-normal rounded-md mr-2'
									className='label-tags product'
								>
									{tag}
								</Tag>
							);
						})}
					</div>
				</InnerContainer>
			</NavLink>
		</OuterContainer>
	);
};

export default SingleProduct;
