import React from "react";

//? STYLED COMPONENTS
import styled from "styled-components";
import { MEDIA_URL } from "../../constants/env";

const OuterContainer = styled.div`
	width: 23.5%;
	height: auto;
	margin-right: 1.5%;

	background: ${({ background }) => background.primary};
	background: linear-gradient(130deg, ${({ background }) => background.primary} 10%, ${({ background }) => background.secondary} 100%);

	@media screen and (min-width: 768px) and (max-width: 1500px) {
	}

	@media screen and (max-width: 768px) {
		width: 48%;
		margin-right: 2%;
	}
`;

const Client = ({ id, pallete, img }) => {
	return (
		<OuterContainer className="rounded-lg md:rounded-xl flex justify-center items-center mb-2 md:mb-7" background={pallete}>
			<img src={MEDIA_URL.concat(img)} alt={`client ${id}`} />
		</OuterContainer>
	);
};

export default Client;
