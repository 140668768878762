import "animate.css";
import React, { useEffect } from "react";
import { projects } from "../constants/projects";
import { services } from "../constants/services";
//? REACT ICONS
import { FiArrowRight, FiPhone } from "react-icons/fi";
import { NavLink } from "react-router-dom";
//? SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import WOW from "wowjs";
import About from "../components/About";
import OutlineButton from "../components/Button/OutlineButton";
import SolidButton from "../components/Button/SolidButton";
import Footer from "../components/Footer";
//? COMPONENTS
import Hero from "../components/Hero";
import Products from "../components/Product";
import Project from "../components/Project";
import HomeService from "../components/Service/Home";
import Why from "../components/Why";

const Home = () => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  return (
    <>
      <section>
        <Hero />
      </section>

      <section>
        <About />
      </section>

      <section>
        <div className="container md:px-24 overflow-x-visible project-section">
          <div className="my-14 xl:my-20 flex flex-col items-start justify-center md:items-end">
            <div className="w-full flex justify-end">
              <div className="flex flex-col text-right wow animate__animated animate__fadeInUp">
                <p className="section-heading-text">
                  <span className="text-primary-500">featured</span> projects
                </p>
                <p className="section-subheading-text">
                  Explore some of our latest projets.
                </p>
              </div>
            </div>
          </div>

          <div className="project-sectionn">
            {projects.slice(0, 3).map((project, index) => {
              return (
                <Project
                  key={index}
                  title={project.title}
                  heading={project.heading}
                  descr={project.descr}
                  logo={project.logo}
                  pallete={project.pallete}
                  img={project.img}
                  orientation={project.orientation}
                  link={project.link}
                />
              );
            })}
          </div>

          <div className="relative my-14 xl:my-28 flex items-center justify-center wow animate__animated animate__fadeInUp">
            <NavLink to="/contact">
              <SolidButton
                text={"contact us"}
                extraClass={"bg-primary-500 text-white px-6"}
              />
            </NavLink>
            <NavLink to="/portfolio">
              <OutlineButton
                text={null}
                extraClass={
                  "ml-2 sm:ml-4 text-primary-500 with-icon px-6 sm:px-8"
                }
                outlineColor={"#24CE7B"}
              >
                <div className="text">view more projects</div>
                <FiArrowRight className="icon" />
              </OutlineButton>
            </NavLink>
          </div>
        </div>
      </section>

      <div className="section-border">
        <hr />
      </div>

      <section className="hidden">
        <div className="w-full relative overflow-hidden">
          <div className="container md:px-24">
            <div className="my-14 xl:my-20 flex flex-col items-start justify-center">
              <div className="w-full flex justify-start">
                <div
                  className="flex flex-col text-left wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <p className="section-heading-text">
                    <span className="text-primary-500">our</span> products
                  </p>
                  <p className="section-subheading-text">
                    Here are some of our in house developed products.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container md:px-24 mb-14 xl:mb-28 overflow-x-visible">
            <Products />
          </div>
        </div>
      </section>

      <div className="section-border">
        <hr />
      </div>

      <section>
        <div className="w-full relative overflow-hidden">
          <div className="container md:px-24">
            <div className="my-14 xl:my-20 flex flex-col items-start justify-center md:items-end">
              <div className="w-full flex justify-end">
                <div
                  className="flex flex-col text-right wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <p className="section-heading-text">
                    <span className="text-primary-500">top</span> services
                  </p>
                  <p className="section-subheading-text">
                    Here are some of our top services.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden xl:flex flex-row container w-full md:px-24">
            {services.map((service, index) => {
              return (
                <div key={index} className="w-full lg:w-1/4 flex px-2">
                  <HomeService
                    id={service.id}
                    pallete={service.pallete}
                    icon={service.icon}
                    heading={service.heading}
                    text={service.text}
                    subText={service.subText}
                  />
                </div>
              );
            })}
          </div>

          <div className="flex xl:hidden w-full">
            <div className="container md:px-24 overflow-x-visible top-services-slider">
              <Swiper
                spaceBetween={25}
                slidesPerView={"auto"}
                onSwiper={null}
                centeredSlides={false}
              >
                {services.map((service, index) => {
                  return (
                    <SwiperSlide key={index} className="mx-2">
                      <HomeService
                        id={service.id}
                        pallete={service.pallete}
                        icon={service.icon}
                        heading={service.heading}
                        text={service.text}
                        subText={service.subText}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>

          <div className="container py-14 xl:py-24 md:px-24">
            <div
              className="h-auto w-full flex items-center justify-center wow animate__animated animate__fadeInUp"
              data-wow-delay="0.3s"
            >
              <NavLink to="/contact">
                <SolidButton
                  text={"contact us"}
                  extraClass={"bg-primary-500 text-white px-6"}
                />
              </NavLink>
              <NavLink to="/about">
                <OutlineButton
                  text={null}
                  extraClass={
                    "ml-2 sm:ml-4 text-primary-500 with-icon px-7 sm:px-8"
                  }
                  outlineColor={"#24CE7B"}
                >
                  <div className="text">view all services</div>
                  <FiArrowRight className="icon" />
                </OutlineButton>
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Why />
      </section>

      <Footer logoClass="hidden" usedForNav={false}>
        <div className="lets-talk-section">
          <h1
            className="heading wow animate__animated animate__fadeInUp"
            data-wow-delay="0.3s"
          >
            Want to work together?
          </h1>
          <h1
            className="subheading wow animate__animated animate__fadeInUp"
            data-wow-delay="0.5s"
          >
            Let's talk.
          </h1>

          <div className="w-full flex flex-row justify-center items-center">
            <NavLink to="/contact">
              <SolidButton
                text={"start a project"}
                extraClass={"bg-primary-500 text-white px-6"}
              />
            </NavLink>
            <a href="tel:+5978925686">
              <OutlineButton
                text={null}
                extraClass={
                  "ml-2 sm:ml-4 text-primary-500 with-icon px-7 sm:px-8"
                }
                outlineColor={"#24CE7B"}
              >
                <div className="text">+597 892-5686</div>
                <FiPhone className="icon" />
              </OutlineButton>
            </a>
          </div>
        </div>
      </Footer>
    </>
  );
};

export default Home;
