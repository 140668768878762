import React, { useEffect } from "react";
import { Parallax } from "react-scroll-parallax";

import "animate.css";
import WOW from "wowjs";

//? COMPONENTS
// import SolidButton from '../Button/SolidButton/index';

//? STYLED COMPONENTS
import { FiArrowRight } from "react-icons/fi";
import styled from "styled-components";
import { MEDIA_URL } from "../../constants/env";

const OuterContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  @media screen and (max-width: 768px) {
    padding-right: 0;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  display: inline-flex;
  transition: all 0.4s ease-in-out;
  border-radius: 1.2rem;
  background: linear-gradient(
    90deg,
    ${({ background }) => background.primary} 10%,
    ${({ background }) => background.secondary} 100%
  );

  @media screen and (max-width: 640px) {
    background: linear-gradient(
      180deg,
      ${({ background }) => background.primary} 10%,
      ${({ background }) => background.secondary} 100%
    );
  }

  &:hover {
    transform: scale(1.015);
    /* box-shadow: ${({ background }) =>
      `${background.primary}44`} 0px 18px 50px -10px; */
    /* box-shadow: ${({ background }) =>
      `${background.primary}11`} 0px 4px 16px, ${({ background }) =>
      `${background.primary}55`} 0px 8px 32px; */
    box-shadow: ${({ background }) => `${background.primary}77`} -4px 9px 25px -6px;
  }
`;

const Tag = styled.div`
  background: ${({ background }) => background};
`;

const Project = ({
  id,
  title,
  heading,
  descr,
  logo,
  pallete,
  img,
  orientation,
  link,
}) => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  return (
    <OuterContainer className="relative group single-project">
      <a href={link} rel="noopener noreferrer" target="_blank">
        <InnerContainer background={pallete} className="">
          <div className="w-full h-full xl:h-project flex flex-col sm:flex-row p-6 py-8 xl:p-16">
            <div className="flex-1 flex-grow flex flex-col wow animate__animated animate__fadeInUp">
              <img
                src={MEDIA_URL.concat(logo)}
                alt={`${title} logo`}
                className="object-contain"
                style={{ height: 100, objectPosition: "left" }}
              />
              <h1 className="font-semibold text-base lg:text-2xl font-inter mt-6" style={{ color: pallete.text }}>
                {heading}
              </h1>
              <p className="paragraph-text text-sm lg:text-base font-light tracking-wide my-4" style={{ color: pallete.text }}>
                {descr}
              </p>
              <div className="hidden sm:flex w-full my-4 xl:my-6">
                <Tag background={pallete.tag} className="label-tags">
                  website
                </Tag>
                <Tag background={pallete.tag} className="label-tags">
                  marketing
                </Tag>
              </div>
              <div className="hidden md:flex lg:opacity-0 transition-opacity duration-300 md:group-hover:opacity-100 items-center gap-2 md:mt-8 lg:mt-auto">
                <p className="-translate-y-44 font-light mt-auto" style={{ color: pallete.text }}>
                  Visit website
                </p>
                <FiArrowRight style={{ color: pallete.text }} />
              </div>
            </div>
            <div className="flex-1 flex-grow flex flex-col relative sm:-right-20 md:-right-16 lg:-right-24 xl:-right-28">
              <Parallax className="m-auto py-8 sm:py-0" y={[-5, 5]}>
                <img
                  src={MEDIA_URL.concat(img)}
                  alt={`${title} project`}
                  // className="transform scale-130 sm:scale-110 md:scale-130 lg:scale-100 xl:scale-120 wow animate__animated animate__fadeIn "
                  className="transform scale-120 wow animate__animated animate__fadeIn lg:scale-100 xl:scale-120"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.3s"
                />
              </Parallax>
              <div className="flex flex-col">
                <div className="flex sm:hidden w-full mt-4 xl:my-6">
                  <Tag background={pallete.tag} className="label-tags">
                    website
                  </Tag>
                  <Tag background={pallete.tag} className="label-tags">
                    marketing
                  </Tag>
                </div>
                <div className="flex md:hidden mt-8 transition-opacity duration-300 items-center gap-2">
                  <p className="-translate-y-44 font-light mt-auto hover:transform" style={{ color: pallete.text }}>
                    Visit website
                  </p>
                  <FiArrowRight style={{ color: pallete.text }} />
                </div>
              </div>
            </div>
          </div>
        </InnerContainer>
      </a>
    </OuterContainer>
  );
};

export default Project;
