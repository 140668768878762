import "animate.css";
import React, { useEffect } from "react";
//? REACT ICONS
import { FiArrowRight, FiPhone } from "react-icons/fi";
import { NavLink } from "react-router-dom";
//? STYLED COMPONENTS
import styled from "styled-components";
import WOW from "wowjs";
import OutlineButton from "../components/Button/OutlineButton";
//? COMPONENTS
import SolidButton from "../components/Button/SolidButton";
import Footer from "../components/Footer";
import { MEDIA_URL } from "../constants/env";

const CustomColorText = styled.p`
	color: #ff146f;
	font-size: 2rem;

	@media screen and (min-width: 768px) {
		font-size: 48px;
	}
`;

const GradientContainer = styled.div`
	max-height: 863px;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 2rem;

	&::before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgb(30, 182, 236);
		background: linear-gradient(90deg, rgba(255, 20, 11, 1) 0%, rgba(248, 118, 168, 1) 100%);
		width: 100%;
		height: 70%;
		z-index: 9;

		@media screen and (max-width: 768px) {
			height: 60%;
		}
	}

	img {
		position: relative;
		z-index: 10;
		width: 100%;
		height: auto;
	}
`;

const PricingContainer = styled.div`
	max-height: 540px;
	height: 100%;
	width: 95%;
	padding: 2rem;
	margin: auto;
	box-shadow: 1px 1px 1rem #e9e9e9;

	&.gradient {
		background: rgb(248, 118, 168);
		background: linear-gradient(45deg, rgba(248, 118, 168, 1) 0%, rgba(255, 255, 255, 0) 80%);
	}

	&.solid {
		background: #ff146f;
	}

	@media screen and (max-width: 768px) {
		width: 85%;
		margin: 0.5rem 0;
	}
`;

const ClientTag = styled.div`
	background: #fd84b2;
	margin-right: 1rem;
`;

const ProductFlexPos = () => {
	useEffect(() => {
		const wow = new WOW.WOW({
			live: false,
		});
		wow.init();
	}, []);

	return (
		<>
			<section>
				<div className="container md:px-24">
					<div className="w-full flex flex-col pt-6 md:pt-20 pb-10 md:pb-16">
						<CustomColorText className="font-inter font-light wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
							Product
						</CustomColorText>
						<p className="text-neutral-500 text-4xl md:text-6xl font-inter font-bold my-4 wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
							Flex Pos
						</p>

						<div className="w-full sm:w-4/6 mt-5 md:mt-16">
							<p className="text-neutral-500 text-sm lg:text-lg font-inter paragraph-text leading-loose lg:leading-loose wow animate__animated animate__fadeInUp" data-wow-delay="0.7s">
								User-friendly Point of Sale software. Set-up in minutes, sell in seconds. Compatible with any device. No installation and no specific hardware required. Extremely flexible, you can configure Flex Pos to meet your precise needs.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section>
				<GradientContainer>
					<div className="container">
						<img loading="lazy" src={MEDIA_URL.concat("/resources/img/product-pos-banner-gradient.png")} alt="" className="transform scale-130 sm:scale-100" />
					</div>
				</GradientContainer>
			</section>

			<section>
				<div className="container md:px-24">
					<div className="w-full flex flex-col-reverse md:flex-row py-5 md:py-10">
						<div className="w-full py-5 sm:py-24 sm:w-1/2 flex flex-col">
							<CustomColorText className="font-inter font-light wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
								Point of Sale
							</CustomColorText>
							<p className="text-neutral-500 text-4xl md:text-6xl font-inter font-bold my-4 wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
								Flex Pos
							</p>

							<div className="w-full mt-5 md:mt-16">
								<p className="text-neutral-500 text-sm lg:text-lg font-inter paragraph-text leading-loose lg:leading-loose wow animate__animated animate__fadeInUp" data-wow-delay="0.7s">
									User-friendly Point of Sale software. Set-up in minutes, sell in seconds. Compatible with any device. No installation and no specific hardware required. Extremely flexible, you can configure Flex Pos to meet your precise needs. A point of sale (POS) is a place where a customer executes the payment for goods or services and where sales taxes may become payable. A POS transaction may occur in person or online.
								</p>
							</div>

							<div className="w-full mt-10 md:mt-20">
								<p className="text-neutral-500 text-sm md:text-lg font-inter paragraph-text wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
									Services:
								</p>
								<div className="w-full flex flex-row mt-2 sm:mt-4 wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
									<ClientTag className="label-tags">pos</ClientTag>
									<ClientTag className="label-tags">marketing</ClientTag>
								</div>
							</div>
						</div>
						<div className="w-full py-5 sm:py-24 sm:w-1/2 flex justify-center items-center">
							<img loading="lazy" src={MEDIA_URL.concat("/resources/img/product-pos-client.png")} alt="" className="w-5/6 wow animate__animated animate__fadeIn" data-wow-duration="1.5s" />
						</div>
					</div>
				</div>
			</section>

			<section>
				<img loading="lazy" src={MEDIA_URL.concat("/resources/img/product-pos-banner.png")} alt="" className="w-full object-cover" />
			</section>

			<section>
				<div className="container md:px-24 py-10 md:py-20">
					<div className="w-full flex flex-col pb-6 md:pb-14">
						<div className="w-full flex justify-end">
							<div className="flex flex-col text-right">
								<p className="text-2xl lg:text-4xl text-neutral-500 font-inter font-semibold capitalize wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
									<span style={{ color: "#FF146F" }}>featured</span> screen 1
								</p>
								<p className="text-sm sm:text-base text-neutral-500 font-inter font-light mt-2 wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
									Here are some home features.
								</p>
							</div>
						</div>
						<div className="w-full flex py-5 md:py-10">
							<img loading="lazy" src={MEDIA_URL.concat("/resources/img/product-pos-web-1.png")} alt="" className="w-full object-cover" />
						</div>
					</div>

					<div className="w-full flex flex-col pb-6 md:pb-14">
						<div className="w-full flex justify-start">
							<div className="flex flex-col text-left">
								<p className="text-2xl lg:text-4xl text-neutral-500 font-inter font-semibold capitalize wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
									<span style={{ color: "#FF146F" }}>featured</span> screen 2
								</p>
								<p className="text-sm sm:text-base text-neutral-500 font-inter font-light mt-2 wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
									Here are some shopping features.
								</p>
							</div>
						</div>
						<div className="w-full flex py-5 md:py-10">
							<img loading="lazy" src={MEDIA_URL.concat("/resources/img/product-pos-web-1.png")} alt="" className="w-full object-cover" />
						</div>
					</div>

					<div className="w-full flex flex-col pb-6 md:pb-14">
						<div className="w-full flex justify-end">
							<div className="flex flex-col text-right">
								<p className="text-2xl lg:text-4xl text-neutral-500 font-inter font-semibold capitalize wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
									<span style={{ color: "#FF146F" }}>featured</span> screen 3
								</p>
								<p className="text-sm sm:text-base text-neutral-500 font-inter font-light mt-2 wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
									Here are some details.
								</p>
							</div>
						</div>
						<div className="w-full flex py-5 md:py-10">
							<img loading="lazy" src={MEDIA_URL.concat("/resources/img/product-pos-web-1.png")} alt="" className="w-full object-cover" />
						</div>
					</div>

					<div className="relative my-5 xl:my-14 flex items-center justify-center wow animate__animated animate__fadeInUp">
						<div className="flex wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
							<NavLink to="/contact">
								<SolidButton fillColor={"#FF146F"} text={"contact us"} extraClass={"bg-primary-500 text-white px-4 sm:px-6"} />
							</NavLink>
							<NavLink exact to="/">
								<OutlineButton text={null} extraClass={"ml-2 sm:ml-4 text-primary-500 with-icon px-6 sm:px-8"} outlineColor={"#FF146F"} textColor={"#FF146F"}>
									<div className="text">view more products</div>
									<FiArrowRight className="icon" />
								</OutlineButton>
							</NavLink>
						</div>
					</div>
				</div>
			</section>

			<div className="section-border">
				<hr />
			</div>

			<section>
				<div className="container md:px-24">
					<div className="w-full flex flex-col mt-10 md:mt-16">
						<CustomColorText className="font-inter font-light wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
							Pricing
						</CustomColorText>
						<p className="text-neutral-500 text-4xl md:text-6xl font-inter font-bold mt-4 wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
							Flex Pos
						</p>
					</div>
					<div className="w-full flex flex-col md:flex-row my-10 md:my-24">
						<div className="w-full md:w-1/3 flex flex-col justify-center items-center">
							<PricingContainer className="gradient rounded-2xl flex flex-col">
								<h4 className="text-2xl text-neutral-500 font-inter font-medium capitalize">starter</h4>
								<p className="paragraph-text text-neutral-500 font-inter font-light mt-6">Starter implements features for small companies.</p>

								<div className="w-full my-10 md:my-16">
									<h1 style={{ color: "#FF146F" }} className="text-5xl text-center font-inter font-bold wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
										$99
									</h1>
									<p style={{ color: "#FF146F" }} className="text-base text-center font-inter font-light">
										per month
									</p>
								</div>

								<button style={{ background: "#FF146F", color: "#fff" }} className="rounded-xl m-auto mb-10 md:mb-16 py-4 px-8 flex justify-center items-center text-base font-inter font-bold uppercase">
									get started
								</button>
							</PricingContainer>
						</div>
						<div className="w-full md:w-1/3 flex flex-col justify-center items-center">
							<PricingContainer className="solid rounded-2xl flex flex-col">
								<h4 className="text-2xl text-white font-inter font-medium capitalize">business</h4>
								<p className="paragraph-text text-white font-inter font-light mt-6">Business implements features for medium companies.</p>

								<div className="w-full my-10 md:my-16">
									<h1 style={{ color: "#fff" }} className="text-5xl text-center font-inter font-bold wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
										$199
									</h1>
									<p style={{ color: "#fff" }} className="text-base text-center font-inter font-light">
										per month
									</p>
								</div>

								<button style={{ color: "#FF146F", background: "#fff" }} className="rounded-xl m-auto mb-10 md:mb-16 py-4 px-8 flex justify-center items-center text-base font-inter font-bold uppercase">
									get started
								</button>
							</PricingContainer>
						</div>
						<div className="w-full md:w-1/3 flex flex-col justify-center items-center">
							<PricingContainer className="gradient rounded-2xl flex flex-col">
								<h4 className="text-2xl text-neutral-500 font-inter font-medium capitalize">enterprise</h4>
								<p className="paragraph-text text-neutral-500 font-inter font-light mt-6">Entreprise implements features for large companies.</p>

								<div className="w-full my-10 md:my-16">
									<h1 style={{ color: "#FF146F" }} className="text-5xl text-center font-inter font-bold wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
										$299
									</h1>
									<p style={{ color: "#FF146F" }} className="text-base text-center font-inter font-light">
										per month
									</p>
								</div>

								<button style={{ background: "#FF146F", color: "#fff" }} className="rounded-xl m-auto mb-10 md:mb-16 py-4 px-8 flex justify-center items-center text-base font-inter font-bold uppercase">
									get started
								</button>
							</PricingContainer>
						</div>
					</div>
				</div>
			</section>

			<section>
				<Footer type="dark" logoType="dark" logoClass="hidden" usedForNav={false}>
					<div className="lets-talk-section">
						<h1 className="heading wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
							Want to work together?
						</h1>
						<h1 className="subheading wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
							Let's talk.
						</h1>

						<div className="w-full flex flex-row justify-center items-center">
							<NavLink to="/contact">
								<SolidButton text={"start a project"} extraClass={"bg-primary-500 text-white px-6"} />
							</NavLink>
							<a href="tel:+5978925686">
								<OutlineButton text={null} extraClass={"ml-2 sm:ml-4 text-primary-500 with-icon px-7 sm:px-8"} outlineColor={"#24CE7B"}>
									<div className="text">+597 892-5686</div>
									<FiPhone className="icon" />
								</OutlineButton>
							</a>
						</div>
					</div>
				</Footer>
			</section>
		</>
	);
};

export default ProductFlexPos;
