import React from 'react';
import { FiChevronRight } from 'react-icons/fi';

export default function BannerMiniGlow() {
  return (
    <a
      href="https://expo.tad.sr"
      className="btn-gradient text-sm font-inter font-bold mb-10 lg:mb-20 uppercase px-4 py-2 text-white cursor-pointer flex items-center"
    >
      <span>
        Discover Our <span className="xl:hidden">UBF</span>
        <span className="hidden xl:inline">United Business Fair</span> 2024 Highlights
      </span>
      <FiChevronRight className="text-lg" style={{ marginLeft: '0.25rem' }} />
    </a>
  );
}
