import React from "react";
import { services } from "../../constants/services";
//? REACT ICONS
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";
import { NavLink } from "react-router-dom";
//? STYLED COMPONENTS
import styled from "styled-components";
//? COMPONENT
import Logo from "../Logo";

const Section = styled.div`
	&.dark {
		background: #001e1c;
		/* background: linear-gradient(
			0deg,
			rgba(60, 60, 59, 1) 0%,
			rgba(68, 68, 66, 1) 90%
		); */

		.dark-shade {
			background: #001715;
		}
	}
`;

const companyLinks = [
	{
		text: "Portfolio",
		href: "/portfolio",
	},
	{
		text: "Products",
		href: "/",
	},
	{
		text: "About",
		href: "/about",
	},
	{
		text: "Contact Us",
		href: "/contact",
	},
];

const socialLinks = [
	{
		text: "Instagram",
		href: "https://www.instagram.com/tadsuriname/",
	},
	{
		text: "Facebook",
		href: "https://www.facebook.com/tad.sr",
	},
	{
		text: "LinkedIn",
		href: "https://www.linkedin.com/company/tad-sr/",
	},
];

const Footer = ({ type, logoType, logoClass, usedForNav, children }) => {
	return (
		<Section className={!type ? "text-neutral-500" : "dark text-white"}>
			{children ?? children}

			<div className={`py-10 md:py-14 flex-col justify-between ${!type ? "w-full bg-gray-100" : "dark-shade"}`}>
				<div className="container w-full md:px-24 flex flex-wrap md:flex-row">
					<div className="flex flex-col w-full mb-5 md:w-1/4">
						<Logo type={logoType} extraClass={logoClass} usedForNav={usedForNav} />
					</div>
					<div className="flex flex-col w-1/2 md:w-1/4">
						<h3 className="footer-heading">services</h3>
						{services.map(({ heading, href }, i) => (
							<NavLink to={href} key={i}>
								<p className="footer-links">{heading}</p>
							</NavLink>
						))}
					</div>
					<div className="flex flex-col w-1/2 md:w-1/4">
						<h3 className="text-xl font-inter font-medium capitalize mb-2 md:mb-5">company</h3>
						{companyLinks.map(({ text, href }, i) => (
							<NavLink to={href} key={i}>
								<p className="footer-links">{text}</p>
							</NavLink>
						))}
					</div>
					<div className="hidden md:flex flex-col w-1/2 md:w-1/4">
						<h3 className="text-xl font-inter font-medium capitalize mb-2 md:mb-5">follow us</h3>
						{socialLinks.map(({ text, href }, i) => (
							<a key={i} href={href} rel="noopener noreferrer" target="_blank">
								<p className="footer-links">{text}</p>
							</a>
						))}
					</div>
				</div>

				<div className="container pt-0 md:pt-16 md:px-24 w-full font-light flex flex-col md:flex-row justify-center md:justify-between">
					<div className="w-full flex justify-center md:hidden py-8">
						<div className="w-2/6 m-auto flex flex-row justify-around">
							<p>
								<FiInstagram />
							</p>
							<p>
								<FiFacebook />
							</p>
							<p>
								<FiLinkedin />
							</p>
						</div>
					</div>

					<div className="flex w-full justify-center md:justify-start">
						<p className="text-sm font-inter font-light">
							Copyright © {new Date().getFullYear()} <span className="font-semibold">tad</span>. All rights reserved.
						</p>
					</div>
					<div className="flex w-full justify-center md:justify-end">
						<NavLink to="/cookie-policy">
							<p className="cursor-pointer hover:underline w-max mx-2 text-sm font-inter font-light">Cookie Policy</p>
						</NavLink>
						<NavLink to="/privacy-policy">
							<p className="cursor-pointer hover:underline w-max mx-2 text-sm font-inter font-light">Privacy Policy</p>
						</NavLink>
					</div>
				</div>
			</div>
		</Section>
	);
};

export default Footer;
