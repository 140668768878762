import React from "react";

//? STYLED COMPONENTS
import styled from "styled-components";
import { MEDIA_URL } from "../../../constants/env";

const Article = styled.div`
	position: relative;
	width: 48.5%;
	height: auto;

	scroll-margin-top: 1rem;

	background: ${({ background }) => background.primary};
	background: linear-gradient(45deg, ${({ background }) => background.primary} 30%, ${({ background }) => background.secondary} 100%);

	@media screen and (min-width: 768px) and (max-width: 1500px) {
		/* height: 40rem; */
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		height: auto;
	}
`;

const Tag = styled.div`
	background: ${({ background }) => background};
`;

const Img = styled.img`
	height: 8rem;
`;

const TopService = ({ id, heading, text, tags, pallete, icon }) => {
	return (
		<Article id={id} background={pallete} className="mb-8 md:mb-7 p-10 flex flex-col rounded-2xl about-service">
			<Img src={MEDIA_URL.concat(icon)} alt="" className="object-contain object-center w-auto" />
			<h1 className="text-2xl lg:text-3xl text-neutral-500 font-inter font-bold my-4 pt-6 capitalize wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
				{heading}
			</h1>
			<p className="paragraph-text text-neutral-500 font-inter leading-loose wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
				{text}
			</p>
			<div className="flex flex-row flex-wrap mt-6 lg:mt-14 wow animate__animated animate__fadeInUp" data-wow-delay="0.7s">
				{tags?.map((tag, index) => {
					return (
						<Tag key={index} background={pallete.tag} className="label-tags">
							{tag}
						</Tag>
					);
				})}
			</div>
		</Article>
	);
};

export default TopService;
