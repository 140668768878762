import React from "react";
import ReactDOM from "react-dom";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";

//? COMPONENTS
import App from "./App";

//? STYLES
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ParallaxProvider>
        <App />
        <Toaster position="top-right" />
      </ParallaxProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
