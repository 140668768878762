import React from "react";
import styled from "styled-components";

const Button = styled.button`
	position: relative;
	border: 1px solid ${({outlineColor}) => outlineColor};
	/* outline: 1px solid ${({outlineColor}) => outlineColor}; */
	/* outline-offset: -1px; */
	color: ${({textColor}) => textColor};

	&:hover {
		box-shadow: 5px 5px 0 ${({outlineColor}) => `${outlineColor}44`};
	}
`

const OutlineButton = ({text, extraClass, onClick, type, outlineColor, textColor, children}) => {
	return (
		<Button type={!type ? 'button' : type} className={`tad-button outline ${extraClass}`} onClick={onClick} outlineColor={outlineColor} textColor={textColor}>
			{text} {children ?? children}
		</Button>
	);
};

export default OutlineButton;
