export const clients = [
	{
		id: 1,
		logo: "/resources/img/client-heineken-logo.png",
		pallete: {
			primary: "#38C15D",
			secondary: "#0A7527",
		},
	},
	{
		id: 2,
		logo: "/resources/img/client-parbo-logo.png",
		pallete: {
			primary: "#F17B92",
			secondary: "#E4002B",
		},
	},
	{
		id: 3,
		logo: "/resources/img/client-telesur-logo.png",
		pallete: {
			primary: "#6260E3",
			secondary: "#110EA1",
		},
	},
	{
		id: 4,
		logo: "/resources/img/client-kings-logo.png",
		pallete: {
			primary: "#FFFF92",
			secondary: "#FEE15B",
		},
	},
	{
		id: 5,
		logo: "/resources/img/client-smart-logo.png",
		pallete: {
			primary: "#9BE2FE",
			secondary: "#1EB6EC",
		},
	},
	{
		id: 6,
		logo: "/resources/img/client-fernandes-logo.png",
		pallete: {
			primary: "#53D794",
			secondary: "#00A652",
		},
	},
	{
		id: 7,
		logo: "/resources/img/client-tba-logo.png",
		pallete: {
			primary: "#385060",
			secondary: "#041C2C",
		},
	},
	{
		id: 8,
		logo: "/resources/img/client-chuck-logo.png",
		pallete: {
			primary: "#9E6ABF",
			secondary: "#532372",
		},
	},
	{
		id: 9,
		logo: "/resources/img/client-allstar-logo.png",
		pallete: {
			primary: "#FD9969",
			secondary: "#F57030",
		},
	},
	{
		id: 10,
		logo: "/resources/img/client-dw-logo.png",
		pallete: {
			primary: "#FF707B",
			secondary: "#C4000F",
		},
	},
	{
		id: 11,
		logo: "/resources/img/client-maze-logo.png",
		pallete: {
			primary: "#5E5E5E",
			secondary: "#000000",
		},
	},
	{
		id: 12,
		logo: "/resources/img/client-queens-logo.png",
		pallete: {
			primary: "#2A0002",
			secondary: "#6F0009",
		},
	},
	// {
	// 	13,
	// 	logo: "/resources/img/client-slimbouw-logo.png",
	// 	pallete: {
  // 		primary: "#FF9EA6",
	// 		secondary: "#EE3342",
	// 	},
	// },
	// {
	// 	14,
	// 	logo: "/resources/img/client-ride-logo.png",
	// 	pallete: {
  // 		primary: "#00000050",
	// 		secondary: "#000000",
	// 	},
	// },
	// {
	// 	15,
	// 	logo: "/resources/img/client-panam-logo.png",
	// 	pallete: {
  // 		primary: "#5A6D8F",
	// 		secondary: "#273348",
	// 	},
	// },
	// {
	// 	16,
	// 	logo: "/resources/img/client-lia-logo.png",
	// 	pallete: {
  // 		primary: "#686868",
	// 		secondary: "#282828",
	// 	},
	// },
	// {
	// 	17,
	// 	logo: "/resources/img/client-cmc-logo.png",
	// 	pallete: {
  // 		primary: "#94B9E0",
	// 		secondary: "#08498C",
	// 	},
	// },
	// {
	// 	18,
	// 	logo: "/resources/img/client-cead-logo.png",
	// 	pallete: {
  // 		primary: "#2B538C",
	// 		secondary: "#75AFD4",
	// 	},
	// },
];