import "animate.css";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Typewriter from "typewriter-effect";
import WOW from "wowjs";
import { MEDIA_URL } from "../../constants/env";
//? COMPONENTS
import OutlineButton from "../Button/OutlineButton/index";
import SolidButton from "../Button/SolidButton/index";
import BannerMiniGlow from "./BannerMiniGlow";

const Hero = () => {
	useEffect(() => {
		const wow = new WOW.WOW({
			live: false,
		});
		wow.init();
	}, []);

	return (
		<>
			<section className="h-auto xl:h-hero hero-section overflow-x-hidden">
				<div className="container relative flex flex-col-reverse md:flex-row h-full md:px-24">
					<div className="w-full md:w-1/2 flex flex-col items-start justify-center py-5">
						<BannerMiniGlow />
						<h1 className="text-5xl xl:text-7xl font-inter text-neutral-500 font-semibold mb-12 xl:mb-48">
							<span className="wow animate__animated animate__fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.8s">
								We build
							</span>
							<br />
							<span className="text-primary-500 wow animate__animated animate__fadeInUp" data-wow-delay="0.5s" data-wow-duration="0.8s">
								out of the box
							</span>
							<div className="wow animate__animated animate__fadeInUp" data-wow-delay="0.7s" data-wow-duration="0.8s">
								<Typewriter
									options={{
										strings: ["designs", "websites", "apps"],
										autoStart: true,
										loop: true,
										delay: "natural",
										pauseFor: 2000,
									}}
								/>
							</div>
						</h1>
						<div className="w-full flex sm:flex-row-reverse justify-between mt-5 sm:mt-0 wow animate__animated animate__fadeInUp" data-wow-delay="0.9s" data-wow-duration="0.8s">
							<NavLink to="/contact">
								<SolidButton text={"contact us"} extraClass={"bg-primary-500 text-white inline px-8 md:hidden"} />
							</NavLink>
							<NavLink to="/portfolio">
								<OutlineButton text={null} extraClass={"text-primary-500 px-8 with-icon"} outlineColor={"#24CE7B"}>
									<div className="text">view our work</div>
									<img src={MEDIA_URL.concat("/resources/img/tad-logo-outline.png")} alt="" className="icon" />
								</OutlineButton>
							</NavLink>
						</div>
					</div>
					<div className="relative w-full md:w-1/2 flex flex-col items-start justify-center py-0 overflow-visible">
						<picture className="relative w-full h-auto inline md:absolute transform scale-107 md:scale-130 xl:scale-180 md:-right-2/4 wow animate__animated animate__fadeIn" data-wow-duration="1.5s">
							<source media="(min-width: 768px)" srcSet={MEDIA_URL.concat("/resources/img/home-hero-banner.png")} />
							<img loading="lazy" src={MEDIA_URL.concat("/resources/img/home-hero-banner-mobile.png")} alt="" />
						</picture>
					</div>
				</div>
			</section>
		</>
	);
};

export default Hero;
