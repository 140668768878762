import React, { useEffect } from "react";

import "animate.css";
import WOW from "wowjs";

//? STYLED COMPONENTS
import styled from "styled-components";

//? REACT ICONS
import { FiArrowRight } from "react-icons/fi";
import { MEDIA_URL } from "../../../constants/env";

const Article = styled.div`
	position: relative;
	width: 350px;
	height: 480px;

	background: ${({ background }) => background.primary};
	background: linear-gradient(45deg, ${({ background }) => background.primary} 30%, ${({ background }) => background.secondary} 100%);

	@media screen and (max-width: 768px) {
		width: 250px;
		height: 500px;
	}

	@media screen and ((min-width: 769px) and (max-width: 1024px)) {
		height: 400px;
	}
`;

const Img = styled.img`
	height: 5rem;
`;

const HomeService = ({ id, pallete, icon, heading, text, subText }) => {
	useEffect(() => {
		const wow = new WOW.WOW({
			live: false,
		});
		wow.init();
	}, []);

	return (
		<Article background={pallete} className="p-8 flex flex-col rounded-2xl relative">
			<Img src={MEDIA_URL.concat(icon)} alt="" className="object-contain object-center wow animate__animated animate__fadeIn" data-wow-duration="1.5s" />
			<h1 className="text-xl md:text-2xl text-neutral-500 font-inter font-medium mt-8 capitalize wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
				{heading}
			</h1>
			<p className="text-neutral-500 font-inter mt-4 wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
				{text}
			</p>
			<p className="text-neutral-500 font-inter mt-2 wow animate__animated animate__fadeInUp" data-wow-delay="0.7s">
				{subText}
			</p>
			<div className="hidden flex-REMOVE flex-row flex-wrap absolute bottom-10 wow animate__animated animate__fadeInUp" data-wow-delay="0.9s">
				<FiArrowRight className="w-6 h-6 text-neutral-500" />
			</div>
		</Article>
	);
};

export default HomeService;
