import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../images/svg/close.svg";
import { useSidebarContext } from "./useSidebar";

const SideBar = () => {
	const { open, onClose } = useSidebarContext();

	const backdropRef = useRef(null);
	const drawerRef = useRef(null);

	const socialLinks = [
		{
			text: "Instagram",
			href: "https://www.instagram.com/tadsuriname/",
		},
		{
			text: "Facebook",
			href: "https://www.facebook.com/tad.sr",
		},
		{
			text: "LinkedIn",
			href: "https://www.linkedin.com/company/tad-sr/",
		},
	];

	useEffect(() => {
		if (open) {
			backdropRef.current?.classList.remove("hidden");
			setTimeout(() => {
				backdropRef.current?.classList.add("opacity-50");
			}, 100);
			setTimeout(() => {
				drawerRef.current?.classList.remove("translate-x-full");
			}, 300);
		} else {
			drawerRef.current?.classList.add("translate-x-full");
			setTimeout(() => {
				backdropRef.current?.classList.remove("opacity-50");
			}, 300);
			setTimeout(() => {
				backdropRef.current?.classList.add("hidden");
			}, 400);
		}
	}, [open]);

	return (
		<>
			{/* FIXME: Workaround dummy div needed for Tailwind to detect classes */}
			<div className="hidden opacity-50"></div>
			<div ref={backdropRef} onClick={onClose} className="fixed bg-white z-50 inset-0 transition-opacity duration-300 opacity-0 hidden"></div>
			<div ref={drawerRef} className="fixed bg-neutral-500 w-full h-full md:w-1/2 flex flex-col inset-y-0 top-0 right-0 z-50 transform translate-x-full transition duration-500 ease-in-out shadow shadow-lg overflow-x-hidden">
				<div className="w-full flex flex-row justify-end top-0 py-7 sm:py-10 px-7 md:px-16 z-10">
					{/* <NavLink to="/contact" onClick={onClose}>
						<SolidButton text={"contact us"} extraClass={"flex bg-primary-500 sidebar-contact text-white mr-5"} />
					</NavLink> */}
					<button className="flex justify-center items-center bg-white text-neutral-500 rounded-lg sidebar-close" onClick={onClose}>
						<CloseIcon className="close-menu-icon font-black" />
					</button>
				</div>
				<div className="w-full h-full flex flex-col items-center md:flex-row">
					<div className="w-full my-auto flex flex-col justify-center items-center text-5xl text-gray-500 capitalize font-inter font-semibold">
						<NavLink activeClassName="text-white" exact to="/" className="py-5 xl:py-8 transition duration-200 hover:text-primary-500" onClick={onClose}>
							home
						</NavLink>
						<NavLink activeClassName="text-white" to="/portfolio" className="py-5 xl:py-8 transition duration-200 hover:text-primary-500" onClick={onClose}>
							portfolio
						</NavLink>
						<NavLink activeClassName="text-white" to="/about" className="py-5 xl:py-8 transition duration-200 hover:text-primary-500" onClick={onClose}>
							about us
						</NavLink>
						<NavLink activeClassName="text-white" to="/contact" className="py-5 xl:py-8 transition duration-200 hover:text-primary-500" onClick={onClose}>
							Contact Us
						</NavLink>
					</div>
					<img loading="lazy" src="/resources/img/tad-logo-grey.png" alt="TAD menu icon" className="absolute pointer-events-none inset-y-0 inset-x-0 opacity-10 sm:opacity-25 m-auto w-full sm:w-5/6 transform scale-80 sm:scale-100 z-0" />
				</div>

				<div className="w-full flex justify-center py-7 sm:py-10">
					<div className="w-5/6 md:w-4/6 m-auto py-4 flex flex-row justify-around items-center text-white text-sm font-inter font-normal">
						{socialLinks.map(({ text, href }, i) => (
							<a key={i} href={href} rel="noopener noreferrer" target="_blank">
								<p className="cursor-pointer hover:underline">{text}</p>
							</a>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default SideBar;
