import React from "react";
import styled from "styled-components";

const Button = styled.button`
	background: ${({fillColor}) => fillColor};
	border: 1px solid ${({fillColor}) => fillColor ?? '#24CE7B'};
	color: ${({textColor}) => textColor};
`;

const SolidButton = ({fillColor, textColor, text, extraClass, onClick, type}) => {
	return (
		<Button type={!type ? 'button' : type} className={`tad-button ${extraClass}`} onClick={onClick} fillColor={fillColor} textColor={textColor}>
			{text}
		</Button>
	);
};

export default SolidButton;
