import "animate.css";
import React, { useEffect } from "react";
import styled from "styled-components";
import WOW from "wowjs";

const LogoFont = styled.p`
	font-size: 9rem;
	text-align: left;
	line-height: 10rem;

	@media screen and (min-width: 769px) and (max-width: 1279px) {
		font-size: 6rem;
		line-height: 7rem;
	}

	@media screen and (max-width: 768px) {
		font-size: 8rem;
		line-height: 9rem;
	}
`;

const About = () => {
	useEffect(() => {
		const wow = new WOW.WOW({
			live: false,
		});
		wow.init();
	}, []);

	return (
		<>
			<div className="container md:px-24 w-full h-auto flex flex-col md:flex-row md:items-center wow animate__animated animate__fadeInUp my-10" data-wow-delay="0.3s">
				<LogoFont className="text-neutral-500 font-montserrat font-black">
					tad
					<span className="text-primary-500 font-monstserrat font-black">.</span>
				</LogoFont>
				<p className="pl-0 sm:pl-5 xl:pl-10 text-lg xl:text-2xl text-neutral-500 font-inter font-light leading-relaxed xl:leading-relaxed">is a leading software development and service agency with talented and motivated people based in Paramaribo, Suriname. We help small, medium and large enterprises build a better business.</p>
			</div>
		</>
	);
};

export default About;
