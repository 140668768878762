import { Select } from "@mantine/core";
import "animate.css";
import emailjs from "emailjs-com";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
//? REACT ICONS
import { BsChevronDown } from "react-icons/bs";
//? STYLED COMPONENTS
import WOW from "wowjs";
import SolidButton from "../components/Button/SolidButton";
import Footer from "../components/Footer";
//? COMPONENTS
import Input from "../components/Inputs/Input";
import Textarea from "../components/Inputs/Textarea";

const Contact = () => {
  return (
    <>
      <section>
        <div className="container md:px-24 w-full flex flex-col md:flex-row py-12 md:pt-32 md:pb-56">
          <div className="w-full md:w-2/5 flex flex-col justify-center items-start p-2 md:p-0">
            <h1
              className="text-4xl md:text-5xl font-inter text-primary-500 font-light wow animate__animated animate__fadeInUp"
              data-wow-delay="0.3s"
            >
              Let's
            </h1>
            <h1
              className="text-5xl md:text-7xl font-inter text-neutral-500 font-semibold mt-3 mb-6 md:mt-4 md:mb-12 wow animate__animated animate__fadeInUp"
              data-wow-delay="0.5s"
            >
              Talk
            </h1>

            <p
              className="mt-5 md:mt-16 font-inter paragraph-text text-normal wow animate__animated animate__fadeInUp"
              data-wow-delay="0.7s"
            >
              Tell us all about your project right here, or send us an email at{" "}
              <a
                href="mailto:info@tad.sr"
                className="text-primary-500 hover:underline"
              >
                info@tad.sr
              </a>
            </p>
          </div>
          <div className="w-full mt-14 md:mt-0 md:w-3/5 flex flex-col justify-center items-end pl-0 md:pl-24">
            <Form />
          </div>
        </div>
      </section>

      <Footer
        type="dark"
        logoType="white"
        logoClass="hidden"
        usedForNav={false}
      >
        {/* <div className="lets-talk-section">
					<h1 className="heading wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
						Want to work together?
					</h1>
					<h1 className="subheading wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
						Let's talk.
					</h1>

					<div className="w-full flex flex-row justify-center items-center">
						<NavLink to="/contact">
							<SolidButton text={"start a project"} extraClass={"bg-primary-500 text-white px-6"} />
						</NavLink>
						<a href="tel:+5978925686">
							<OutlineButton text={null} extraClass={"ml-2 sm:ml-4 text-primary-500 with-icon px-7 sm:px-8"} outlineColor={"#24CE7B"}>
								<div className="text">+597 892-5686</div>
								<FiPhone className="icon" />
							</OutlineButton>
						</a>
					</div>
				</div> */}
      </Footer>
    </>
  );
};

export default Contact;

function ReCaptcha() {
  // reCaptcha
  // sitekey: 6Lef4KAkAAAAALjYS5JZqOc0F_aywq07SvPscGuO,
  // secretkey: 6Lef4KAkAAAAAKZG2wFHg12CVPs57iI0FcZAhrHB

  // Testing
  // Site key: 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
  // Secret key: 6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe

  return (
    <div className="w-full flex flex-row">
      <div className="w-full p-2 md:p-4">
        <div
          className="g-recaptcha"
          data-sitekey="6Lef4KAkAAAAALjYS5JZqOc0F_aywq07SvPscGuO"
        ></div>
      </div>
    </div>
  );
}

function Form() {
  const sendEmail = (e) => {
    e.preventDefault();

    const waitLoader = document.querySelector(".wait-loader");
    waitLoader.classList.add("active");

    emailjs
      .sendForm(
        "service_ionos_tad",
        "template_uqro94m",
        e.target,
        "ofqtUMf-NF7eQR0rJ"
      )
      .then(
        (result) => {
          // console.log(result.text);
          toast.success(
            "We have received your message! We will contact you as soon as possible"
          );

          e.target.reset();
          waitLoader.classList.remove("active");
        },
        (error) => {
          // console.log(error.text);
          toast.error("Oops, something went wrong. Please try again");
          waitLoader.classList.remove("active");
        }
      );
  };

  const budgets = [
    { value: "$1-$2k", label: "$1k - $2k" },
    { value: "$2-$5k", label: "$2k - $5k" },
    { value: "$5-$10k", label: "$5k - $10k" },
    { value: "$10k+", label: "> $10k" },
  ];

  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  // Fetch Countries
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetch("https://restcountries.com/v2/all?fields=name")
      .then((response) => response.json())
      .then((data) => setCountries(data.sort()));
  }, []);

  const listCountries = countries.map((country) => ({
    value: country.name,
    label: country.name,
  }));

  return (
    <form
      onSubmit={sendEmail}
      className="wow animate__animated animate__fadeIn"
      data-wow-duration="1.5s"
    >
      <div className="w-full flex flex-row">
        <div className="w-1/2 p-2 md:p-4">
          <Input
            type="text"
            name="from_name"
            placeholder="Name*"
            isRequired={true}
          />
        </div>
        <div className="w-1/2 p-2 md:p-4">
          <Input
            type="text"
            name="from_phone"
            placeholder="Phone*"
            isRequired={false}
          />
        </div>
      </div>
      <div className="w-full flex flex-row">
        <div className="w-1/2 p-2 md:p-4">
          <Input
            type="email"
            name="from_email"
            placeholder="Email*"
            isRequired={true}
          />
        </div>
        <div className="w-1/2 p-2 md:p-4">
          <Input
            type="text"
            name="from_company"
            placeholder="Company*"
            isRequired={false}
          />
        </div>
      </div>
      <div className="w-full flex flex-row">
        <div className="w-full p-2 md:p-4">
          <Input
            type="text"
            name="hear_from"
            placeholder="How did you hear about us*"
            isRequired={true}
          />
        </div>
      </div>
      <div className="w-full flex flex-row">
        <div className="w-full p-2 md:p-4">
          <Textarea
            name="project"
            placeholder="Tell us about your project*"
            rows="5"
            isRequired={true}
          />
        </div>
      </div>
      {/* Country */}
      <div className="w-full flex flex-row">
        <div className="w-full p-2 md:p-4">
          <Select
            searchable
            rightSection={<BsChevronDown size={14} />}
            data={listCountries}
            placeholder="Country*"
            name="from_country"
          />
        </div>
      </div>
      {/* Budget */}
      <div className="w-full flex flex-row">
        <div className="w-full p-2 md:p-4">
          <Select
            data={budgets}
            placeholder="Budget*"
            name="budget"
            rightSection={<BsChevronDown size={14} />}
          />
        </div>
      </div>

      <ReCaptcha />
      <div className="w-full flex flex-row">
        <div className="w-full p-2 md:p-4">
          <SolidButton
            type={"submit"}
            text={"submit"}
            extraClass={"bg-primary-500 text-white px-10 w-full"}
          />
        </div>
      </div>
    </form>
  );
}
