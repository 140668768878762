export const projects = [
  {
    id: 1,
    title: "King's Enterprises N.V.",
    descr: "King's Enterprises N.V. is Suriname's largest specialist in the field of alcoholic beverages and Duty-Free stores. With their extensive sales network and strategic location, they can offer products to everyone. Their daily activities consist of retail, wholesale, distribution, E-commerce and duty free.",
    heading: "All Hail The King",
    logo: "/resources/img/project-kings-logo.png?v=2",
    pallete: {
      primary: "#FEE15B",
      secondary: "#FFFF92",
      text: "#171717",
      tag: "#171717",
    },
    img: "/resources/img/project-kings-banner.png?v=2",
    orientation: "flex-row",
    link: "https://www.kings.sr",
  },
  {
    id: 2,
    title: "Smart Connexxionz",
    descr: "Smart connexxionz is the first end-to-end gadgets ecommerce platform in Suriname. It is a smart solution that allows people to discover, try and buy the latest gadgets. ",
    heading: "The Ultimate Gadget Pleasure",
    logo: "/resources/img/project-smart-logo.png?v=2",
    pallete: {
      primary: "#1EB6EC",
      secondary: "#9BE2FE",
      text: "#FFFFFF",
      tag: "#5BCCF5",
    },
    img: "/resources/img/project-smart-banner.png?v=2",
    orientation: "flex-row",
    link: "https://www.smartconnexxionz.com/",
  },
  {
    id: 3,
    title: "Queens Hotel",
    descr: "Queens hotel allows people to create and confirm bookings online effortlessly. This experience upsells potential guests with attractive packages including tickets, tours and activities.",
    heading: "The Luxurious Experience",
    logo: "/resources/img/project-queens-logo.png?v=2",
    pallete: {
      primary: "#72000A",
      secondary: "#320005",
      text: "#FFFFFF",
      tag: "#F87F89",
    },
    img: "/resources/img/project-queens-banner.png?v=2",
    orientation: "flex-row",
    link: "https://www.queenshotelsuriname.com",
  },
  {
    id: 4,
    title: "Trustbank Amanah Mijn Aanvraag",
    descr: "Trustbank Amanah has grown substantially since its inception in 1989. It now provides Islamic banking services, aiming to transform the financial landscape in Suriname, the Caribbean, and Latin America, with aspirations to become a regional financial hub.",
    heading: "Confidence Is The Key",
    logo: "/resources/img/project-tba-logo.png?v=2",
    pallete: {
      primary: "#041C2C",
      secondary: "#1e293b",
      text: "#FFFFFF",
      tag: "#A69F88",
    },
    img: "/resources/img/project-tba-banner.png?v=2",
    orientation: "flex-row",
    link: "https://mijneaanvraag.trustbankamanah.com",
  },
	{
		id: 5,
		title: "CEAD N.V.",
		descr: "CEAD is a market leader in industrial solutions. It continuously specializes in products that are both more durable under harsh environmental conditions and longer lasting.",
		heading: "The EPC Specialist",
		logo: "/resources/img/project-cead-logo.png?v=2",
		pallete: {
			primary: "#63A4CF",
			secondary: "#006AB0",
      text: "#FFFFFF",
			tag: "#006AB0",
		},
		img: "/resources/img/project-cead-banner.png?v=2",
		orientation: "flex-row",
		link: "https://www.ceadnv.com",
	},
	{
		id: 6,
		title: "Alpha 597",
		descr: "Alpha 597 has marked its territory in cigars retail, vehicles sourcing and being the latest logistics partner for shipments from all around Europe.",
		heading: "The Alpha's Territory",
		logo: "/resources/img/project-alpha-597-logo.png?v=2",
		pallete: {
			primary: "#4D4D4D",
			secondary: "#B2B2B2",
      text: "#FFFFFF",
			tag: "#EF4444",
		},
		img: "/resources/img/project-alpha-597-banner.png?v=2",
		orientation: "flex-row",
		link: "https://www.alpha-597.com/",
	},
  {
    id: 7,
    title: "EAG Airport",
    descr: "Heliport Paramaribo, an ICAO certified aerodrome by the Civil Aviation Safety Authority Suriname in 2016, facilitates all helicopter companies that provide services for the oil & gas industry.",
    heading: "The Sky Is The Limit",
    logo: "/resources/img/project-eag-logo.png?v=2",
    pallete: {
      primary: "#2E3192",
      secondary: "hsl(238, 52%, 65%)",
      text: "#FFFFFF",
      tag: "#F04E23",
    },
    img: "/resources/img/project-eag-banner.png?v=2",
    orientation: "flex-row",
    link: "https://eagairport.sr",
  },
  {
    id: 8,
    title: "Rashiv Onroerendgoed",
    descr: "Rashiv Onroerendgoed MIJ N.V. prioritizes customer satisfaction through sustainable development and an integrated team approach. They aim for long-term continuity for all stakeholders.",
    heading: "Where Your Real Estate Journey Begins",
    logo: "/resources/img/project-rashiv-logo.png?v=2",
    pallete: {
      primary: "#128BC0",
      secondary: "hsl(198, 83%, 70%)",
      text: "#FFFFFF",
      tag: "#86B640",
    },
    img: "/resources/img/project-rashiv-banner.png?v=2",
    orientation: "flex-row",
    link: "https://rashivvastgoed.nl",
  },
	{
		id: 9,
		title: "Suriname Natural Stone Co N.V.",
		descr: "Suriname Natural Stone is ruling the small-scale mining industry in Suriname which is currently one of the biggest industries in the local production sector.",
		heading: "The Golden Rule",
		logo: "/resources/img/project-snsco-logo.png?v=2",
		pallete: {
			primary: "#FFC65A",
			secondary: "#E79F18",
      text: "#FFFFFF",
			tag: "#E79F18",
		},
		img: "/resources/img/project-snsco-banner.png?v=2",
		orientation: "flex-row",
		link: "https://www.surinamenaturalstoneco.com/",
	},
	{
    id: 10,
		title: "Slimbouw",
		descr: "Website, marketing and design for one of Suriname’s leading construction groups, catering to millions of visitors. Here’s how we helped Slimbouw Smart Constructions provide their services.",
		heading: "Smart Constructions",
		logo: "/resources/img/project-slimbouw-logo.png?v=2",
		pallete: {
      primary: "#EE3342",
			secondary: "#FF9FA7",
      text: "#FFFFFF",
			tag: "#F87F89",
		},
		img: "/resources/img/project-slimbouw-banner.png?v=2",
		orientation: "flex-row",
		link: "https://www.slimbouw.com/",
	},
  {
    id: 11,
    title: "Troytec Video Productions",
    descr: "TroyTec Video Productions specializes in professional video recording, digital video editing, graphic designs, wide format printing and drone/hand-held photography.",
    heading: "The Magic Of Unleashed Creativity",
    logo: "/resources/img/project-troytec-logo.png?v=2",
    pallete: {
      primary: "#77c740",
      secondary: "hsl(96, 55%, 70%)",
      text: "#FFFFFF",
      tag: "#1b1e27",
    },
    img: "/resources/img/project-troytec-banner.png?v=2",
    orientation: "flex-row",
    link: "https://troytecgy.com",
  },
  // {
  // 	id: 12,
  // 	title: "Martin's house Indian food",
  // 	descr: "Martin's house Indian food is a mix of a vegetarian restaurant and one for the meat lovers out there, which specializes in Tandoori, Indian-Chinese, Punjabi, South Indian and North Indian cuisines.",
  // 	heading: "The House Of Spices",
  // 	logo: "/resources/img/project-binas-inn-logo.png?v=2",
  // 	pallete: {
  // 		primary: "#AC3434",
  // 		secondary: "#610202",
  //     text: "#FFFFFF",
  // 		tag: "#B49965",
  // 	},
  // 	img: "/resources/img/project-binas-inn-banner.png?v=2",
  // 	orientation: "flex-row",
  // 	link: "https://appartementenbina.com/",
  // },
	// {
	// 	id: 13,
	// 	title: "Legacy In Action",
	// 	descr: "Website, marketing and design for one of Suriname’s top visual companies, catering to millions of visitors. Here’s how we helped My Lia provide their services and get their message across.",
	// 	logo: "/resources/img/project-lia-logo.png?v=2",
	// 	pallete: {
	// 		primary: "#282828",
	// 		secondary: "#686868",
  //     text: "#FFFFFF",
	// 		tag: "#686868",
	// 	},
	// 	img: "/resources/img/project-lia-banner.png?v=2",
	// 	orientation: "flex-row",
	// 	link: "https://www.my-lia.net/",
	// },
	// {
	// 	id: 14,
	// 	title: "Pan American Parts",
	// 	descr: "Website, marketing and design for one of Suriname’s leading CAR PARTS resellers, catering to millions of visitors. Here’s how we helped Pan American Parts provide their services.",
	// 	logo: "/resources/img/project-panam-logo.png?v=2",
	// 	pallete: {
	// 		primary: "#273348",
	// 		secondary: "#5A6D8F",
  //     text: "#FFFFFF",
	// 		tag: "#5A6D8F",
	// 	},
	// 	img: "/resources/img/project-panam-banner.png?v=2",
	// 	orientation: "flex-row",
	// 	link: "https://www.panamericangroup.sr/",
	// },
	// {
	// 	id: 15,
	// 	title: "CMC",
	// 	descr: "Website, marketing and design for one of Suriname’s top media & print consultants, catering to millions of visitors. Here’s how we helped CMC Suriname provide their services.",
	// 	logo: "/resources/img/project-cmc-logo.png?v=2",
	// 	pallete: {
	// 		primary: "#08498C",
	// 		secondary: "#94B9E0",
  //     text: "#FFFFFF",
	// 		tag: "#94B9E0",
	// 	},
	// 	img: "/resources/img/project-cmc-banner.png?v=2",
	// 	orientation: "flex-row",
	// 	link: "http://www.cmcsuriname.com/",
	// },
	// {
	// 	id: 16,
	// 	title: "Ride",
	// 	descr: "Mobile application and design for one of Suriname’s biggest Online Taxi Services, catering to millions of Riders. Here’s how we helped Ride Suriname provide their services.",
	// 	logo: "/resources/img/project-ride-logo.png?v=2",
	// 	pallete: {
	// 		primary: "#000000",
	// 		secondary: "#808080",
  //     text: "#FFFFFF",
	// 		tag: "#808080",
	// 	},
	// 	img: "/resources/img/project-ride-banner.png?v=2",
	// 	orientation: "flex-row",
	// 	link: "https://www.ride.sr/home/",
	// },
];