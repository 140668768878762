export const services = [
  {
    id: "e-commerce",
    heading: "E-Commerce Platform",
    icon: "/resources/img/design-icon.png?v=2",
    text: "Our Flagship Product: Experience unparalleled UI/UX with our proprietary e-commerce platform, designed to empower your online store with cutting-edge technology and seamless shopping experiences.",
    subText: "",
    pallete: {
      primary: "#01F2AD",
      secondary: "#00E356",
      tag: "#001E1C",
    },
    href: "/about?section=e-commerce"
  },
  {
    id: "web-development",
    heading: "Web Development",
    icon: "/resources/img/dev-icon.png?v=2",
    text: "Bespoke Websites: Tailored web design services to create a unique online presence for your business, ensuring it stands out in the digital landscape.",
    subText: "",
    pallete: {
      primary: "#01F2AD",
      secondary: "#00E356",
      tag: "#001E1C",
    },
    href: "/about?section=web-development"
  },
  {
    id: "mobile-development",
    heading: "Mobile Development",
    icon: "/resources/img/network-icon.png?v=2",
    text: "App Innovation: Custom mobile application development to extend your digital reach, enhance customer engagement, and improve operational efficiency on both iOS and Android platforms.",
    subText: "",
    pallete: {
      primary: "#01F2AD",
      secondary: "#00E356",
      tag: "#001E1C",
    },
    href: "/about?section=mobile-development"
  },
  {
    id: "digital-marketing",
    heading: "Digital Marketing",
    icon: "/resources/img/marketing-icon.png?v=2",
    text: "Full-Spectrum Campaigns: Comprehensive digital marketing strategies encompassing social media management, SEO optimization, and engaging video content production to amplify your brand.",
    subText: "",
    pallete: {
      primary: "#01F2AD",
      secondary: "#00E356",
      tag: "#001E1C",
    },
    href: "/about?section=digital-marketing"
  },
];