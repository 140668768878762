import React from "react";
//? STYLED COMPONENTS
import styled from "styled-components";
import { MEDIA_URL } from "../../constants/env";

const Article = styled.div`
	height: 29rem;
	box-shadow: 2px 2px 1rem #e9e9e9;
`;

const ServiceImageContainer = styled.div`
	width: 12rem;
	aspect-ratio: 1;
`;

const Process = ({ counter, header, text, img }) => {
	return (
		<Article className="building-process w-max rounded-2xl overflow-hidden">
			<div className="w-72 md:w-80 h-full bg-white p-8 flex flex-col">
				<div className="w-full h-40 flex flex-row justify-between relative my-4">
					<div className="w-8 h-8 bg-primary-500 bg-opacity-25 flex justify-center items-center rounded-full">
						<p className="paragraph-text text-neutral-500 font-inter font-semibold">{counter ?? "1"}</p>
					</div>
					<ServiceImageContainer>
						<img loading="lazy" src={MEDIA_URL.concat(img)} alt="" className="relative w-full object-contain" />
					</ServiceImageContainer>
				</div>
				<div className="w-full h-auto relative my-4">
					<h1 className="text-xl text-neutral-500 font-inter font-semibold capitalize my-2 wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
						{header ?? "heading"}
					</h1>
					<p className="paragraph-text text-neutral-500 font-inter font-light wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
						{text ?? "text"}
					</p>
				</div>
			</div>
		</Article>
	);
};

export default Process;
